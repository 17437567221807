"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var patientenDebugStore_1 = require("@/state/patientenDebugStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      currentTab: 0
    };
  },
  computed: {
    selectedPatient: {
      get: function get() {
        return patientenDebugStore_1.patientenDebugStore.state.selectedPatient;
      },
      set: function set(val) {
        patientenDebugStore_1.patientenDebugStore.commit.setSelectedPatient(val);
        void patientenDebugStore_1.patientenDebugStore.dispatch.loadLeistungen();
      }
    },
    patids: function patids() {
      return patientenDebugStore_1.patientenDebugStore.state.patids;
    },
    isLoading: function isLoading() {
      return patientenDebugStore_1.patientenDebugStore.state.isLoading;
    }
  },
  created: function created() {
    void patientenDebugStore_1.patientenDebugStore.dispatch.loadPatids();
  }
});