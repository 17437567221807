/* unplugin-vue-components disabled */import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"patienten-debug",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-row align-top"},[_c(VAutocomplete,{staticClass:"mr-4",attrs:{"prepend-icon":"fas fa-search","color":"primary","dense":"","solo":"","flat":"","clearable":"","auto-select-first":"","label":"Patient","placeholder":"Patienten-ID eingeben","items":_vm.patids,"readonly":_vm.isLoading},model:{value:(_vm.selectedPatient),callback:function ($$v) {_vm.selectedPatient=$$v},expression:"selectedPatient"}})],1),_c(VCard,{staticClass:"my-0",attrs:{"flat":""}},[_c(VTabs,{attrs:{"height":"40"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTab,{attrs:{"to":"/patienten-debug/leistungen"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("far fa-suitcase-medical")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Leistungen")])],1),_c(VTab,{attrs:{"disabled":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("far fa-calendar-check")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Termine (in dev)")])],1)],1),_c(VCardText,{staticClass:"pa-0"},[(_vm.isLoading)?_c('rose-loader'):_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }